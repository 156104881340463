.screen {
  background-color: #FFF;
  color: rgba(var(--color-darkpurple));
  width: 300px;
  height: 150px;
  padding: 2rem;
  border-radius: 2.4rem;
  border-top: 3rem solid rgba(var(--color-darkpurple));
  z-index: 1;  
}

.screen::after,
.screen::before {
  content: "";
  position: absolute;
  top: -9px;
  width: 4px;
  height: 4px;
  border-radius: 2px;  
}

.screen::after {
  animation-name: beacon;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: steps(2, jump-none);
  animation-direction: alternate;

  background-color: #000;
  right: 40px;
}
.screen::before {
  background-color: rgba(var(--color-babypurple));
  right: 27px;
}

.screen > pre {
  text-wrap: wrap;
  font-size: 1.2rem;
}

@keyframes beacon {
  0% {
    background-color: #000;
  }
  100% {
    background-color: rgba(var(--color-babypurple));
  }
}

@keyframes mobileAppear {
  0% {
    transform: translate(-50%, -15%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -10%);
    opacity: 1;
  }
}

@media (min-width: 375px) and (max-width: 575px) {
  .screen {
    animation: mobileAppear 300ms ease-in-out ;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -10%);
    height: 200px;
    box-shadow: 0px 5px 30px rgba(194, 192, 218, 0.5);
  }

  .screen::before,
  .screen::after {
    top: -17px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .screen {
    position: absolute;
    border-radius: 1.4rem;
    border-top-width: 1.8rem;
    transform:
      skew(-57deg, 36.91deg)
      translate(350px, 25px)
      scale(0.5);
    box-shadow: 10px 5px 20px rgba(194, 192, 218);
  }

  .screen::before,
  .screen::after {
    top: -12px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
  }
}