/* HOME: HERO SECTION */
.hero__home {
  background-image:
    linear-gradient(rgb(var(--color-babypurple)), #fff);
  width: 100%;
  height: 100vh;
}

.hero__container {
  display: flex;
}

.hero__welcome {
  max-width: 567px;
}

.hero__welcome > h1 {
  font-size: 7.2rem;
  line-height: 1.1;
  margin-bottom: 3.8rem;
}

.hero__graphic {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 768px;
}

.hero__graphic--platform {
  position: relative;
}

/* DEFAULT POSITION OF GRAPHICAL COMPONENTS */
.vector__menu,
.vector__app,
.vector__lmessage,
.vector__smessage,
.vector__lcolumn,
.vector__mcolumn,
.vector__scolumn {
  position: absolute;
}

.vector__platform {
  animation:
    rise 250ms ease-out,
    reveal 250ms ease-out;
}

.vector__menu {
  animation: 
    rise 250ms ease-out 100ms,
    reveal 250ms ease-out 100ms forwards;
  opacity: 0;
  top: 45px;
  left: 40px;
}

.vector__app {
  animation: 
    rise 250ms ease-out 350ms,
    reveal 250ms ease-out 350ms forwards;
  opacity: 0;
  top: 40px;
  left: 147px;
}

.vector__lmessage {  
  animation:
    rise 300ms ease-out 700ms,
    reveal 300ms ease-out 700ms forwards,
    slowBounce 4000ms ease-in-out 1200ms infinite;
  opacity: 0;
  bottom: 130px;
  right: 135px;
  z-index: 1;
}

.vector__smessage {
  animation:
    rise 300ms ease-out 900ms,
    reveal 300ms ease-out 900ms forwards,
    slowBounce 4000ms ease-in-out 2000ms infinite;
  opacity: 0;
  top: -70px;
  right: 235px;
}

.vector__lcolumn {
  animation: 
    rise 250ms ease-out 600ms,
    reveal 250ms ease-out 600ms forwards;
  opacity: 0;
  top: 18px;
  right: 180px;
}
.vector__mcolumn {
  animation: 
    rise 250ms ease-out 500ms,
    reveal 250ms ease-out 500ms forwards;
  opacity: 0;
  top: 78px;
  right: 135px;
}

.vector__scolumn {
  animation: 
    rise 250ms ease-out 400ms,
    reveal 250ms ease-out 400ms forwards;
  opacity: 0;
  top: 140px;
  right: 85px;
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slowBounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes rise {
  0% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fall {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes expand {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(100%);
  }
}

/* MEDIA QUERIES - TO REVISE */
@media (min-width: 375px) and (max-width: 575px) {
  
  .hero__welcome > h1,
  .hero__welcome > .cta-link {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .hero__welcome > h1 {
    top: 120px;
    font-size: 5rem;
    text-align: center;
    line-height: 1;
  }
  .hero__welcome > h1::before {
    content: "";
    position: absolute;
    animation: expand 500ms ease-in-out;
    background-color: #ffd249;
    
    top: 30px;
    left: 100px;
    width: 180px;

    height: 20px;
    border-radius: 10px;
    transform-origin: left;
    z-index: -1;
  }

  .hero__welcome > .cta-link { bottom: 50px; }
  .hero__welcome > p { display: none; }
  
  .hero__graphic { display: none; }
}
  
@media (min-width: 576px) and (max-width: 991px) {
  .hero__container {
    flex-direction: column;
  }  
 
  .hero__welcome > p {
    display: none;
  }

  .hero__welcome > h1 {
    position: absolute;
    font-size: 2rem;
    line-height: 1;
    margin-bottom: unset;
    width: min-content;
    transform: skew(-56deg,36.91deg) translate(405px, -65px);
  }

  .hero__welcome > .cta-link {
    position: absolute;
    transform:
      skew(-57deg, 36.91deg)
      translate(480px, 80px)
      scale(0.75);
    font-size: 1.25rem;
    padding: 0.75rem 2rem;
  }

  .hero__graphic {
    justify-content: flex-start;
  }

  .hero__graphic--platform {
    transform: scale(0.7);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hero__welcome {
    width: 380px;
    margin-left: 100px;
  }
  .hero__welcome > h1 {
    font-size: 4.8rem;
  }

  .hero__graphic {
    width: max-content;   
  }

  .hero__graphic--platform {
    transform: scale(0.8);
  }
}

@media (min-width: 1200px) and (max-width: 1400px) { 
  
  .hero__welcome {
    max-width: 450px;
  }
  .hero__welcome > h1 {
    font-size: 5.4rem;
  }
  
  .hero__graphic--platform {
    transform: scale(0.85);
  }

  .hero__graphic {
    width: max-content;   
  }
}