.image__divider {
  background-image:
    linear-gradient(rgb(var(--color-babypurple)), #fff);
  width: 100%;
  padding-top: 3rem;
  border-radius: 1rem;
  overflow: hidden;
}

.image__divider > img {
  display: block;
  width: min-content;
  margin: 0 auto;
}

@keyframes slideFromRight {
  0% {
    transform: translateX(1.25rem);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-1.25rem);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-width: 991px) {
  .image__divider > img {
    width: 100%;
  }
}