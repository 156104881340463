.facts__sticker {
  flex: 1 0 50%;
  display: flex;
  align-items: center;
  margin-bottom: 3.8rem;
}

.facts__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  flex: 0 0 60px;
  height: 60px;
  border: 1px solid rgba(var(--color-lightpurple), 0.25);
  box-shadow: 0 4px 4px rgb(var(--color-babypurple), 1);
  border-radius: 8px;
  margin-right: 2rem;
}

.facts__text {
  color: inherit;
  font-size: 1.8rem;
}