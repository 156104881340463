h1, h2 {
  color: rgb(var(--color-darkpurple));
  font-weight: 700;
}

p, span {
  font-size: 1.8rem;
}

h1 {
  max-width: 768px;
  font-size: 4.4rem;
  margin-bottom: 6rem;
}

h2 {
  font-size: 2.4rem;
  margin-bottom: 3.8rem;
}

p {
  color: rgb(var(--color-gray));
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 3.8rem;
}

/* P:last-of-type {
  margin-bottom: 0;
} */

/* Google Material Symbols styling */
span.size-48 {
  font-size: 48px;
}

span.branded {
  color: rgb(var(--color-lightpurple));
}

span.light {
  color: #fff;
}