.graph__container {
  animation: 
    reveal 250ms ease-out 950ms forwards;
  display: flex;
  position: absolute;
  top: 275px;
  left: 245px;
  transform: skew(54deg, -32.91deg);
  opacity: 0;
}

.graph__pie {
  position: relative;
  width: 40px;
  height: 40px;
  border: 5px solid #c2c0da;
  border-radius: 50%;
}

.graph__pie::after {
  content: "%";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5rem;
  color: #c2c0da;
}

.graph__bar-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1.6rem;
}

.graph__bar {
  position: relative;
  display: flex;
  flex: 1;
}

.graph__bar > div {
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  transform-origin: left;
}

.graph__bar > .left,
.graph__bar > .right {
  width: 11px;
  background-color: #c2c0da;
}
.graph__bar > .left {
  border-radius: 5px 0 0 5px;
}
.graph__bar > .right {
  left: 10px;
  border-radius: 0 5px 5px 0;
}
.graph__bar > .center {
  left: 10px;
  background-color: #c2c0da;
  width: 1px;
}

/* Animations - 1st bar */
.graph__bar:first-child > .center {
  animation: loading-bar-15 500ms ease-out 1200ms forwards;
}
.graph__bar:first-child > .right {
  animation: move-15 500ms ease-out 1200ms forwards;
}

/* Animations - 2nd bar */
.graph__bar:nth-child(2) > .center {
  animation: loading-bar-50 700ms ease-out 1350ms forwards;
}
.graph__bar:nth-child(2) > .right {
  animation: move-50 700ms ease-out 1350ms forwards;
}

/* Animations - 3rd bar */
.graph__bar:last-child > .center {
  animation: loading-bar-100 900ms ease-out 1500ms forwards;
}
.graph__bar:last-child > .right {
  animation: move-100 900ms ease-out 1500ms forwards;
}

@keyframes move-15 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(14px);
  }
}

@keyframes loading-bar-15 {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(15);
  }
}

@keyframes move-50 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(49px);
  }
}

@keyframes loading-bar-50 {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(50);
  }
}

@keyframes move-100 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(99px);
  }
}

@keyframes loading-bar-100 {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(100);
  }
}