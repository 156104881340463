.modal-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.15);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  backdrop-filter: blur(1rem);
  -webkit-backdrop-filter: blur(1rem);  
}

.modal-dialogue {
  position: fixed;
  top: 50%;
  left: 50%;

  animation-name: slideFromTop;
  animation-duration: 0.25s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  background-color: #fff;
  max-width: 400px;
  font-size: 1.8rem;
  color: rgb(var(--color-darkpurple));
  border-radius: 1.6rem;
  padding: 4rem;
  opacity: 0;
  z-index: 11;
}

/* COLOPHON MODAL */
.modal-dialogue > .cta-link {
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  padding: 0.5rem;
}

.modal-dialogue > a > span {
  font-size: 2.4rem;
  margin: 0;
}

.modal-dialogue > h2 {
  font-size: 2.2rem;
  color: inherit;
  margin-bottom: 2rem;
}

.modal-dialogue__item {
  display: flex;
}

.modal-dialogue__item:not(:last-child) {
  margin-bottom: 1rem;
}

.modal-dialogue__item--icon {
  margin-right: 1.25rem;
}

.modal-dialogue__item--icon > i {
  font-size: 2.4rem;
  color: rgb(var(--color-lightpurple));
}

/* CONTACT FORM MODAL */
.modal-dialogue > form {
  width: 100%;
}

.modal-dialogue input,
.modal-dialogue textarea {
  display: block;
  width: 350px;
  border: 1px solid rgba(var(--color-lightpurple), 0.25);
  border-radius: 8px;
  padding: 1.25rem 1.5rem;
  font-family: inherit;
  font-size: 1.6rem; 
  outline: 0;
  transition: border 0.15s ease;
}

.modal-dialogue input:focus,
.modal-dialogue textarea:focus {
  border: 1px solid rgba(var(--color-lightpurple), 1);
}

.modal-dialogue textarea {
  height: 180px;
  resize: vertical;
}

.modal-dialogue > form > .cta-action {
  width: 100%;
  cursor: pointer;
}

/* Form submission alerts */
.alert {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.6rem;
  border-radius: 8px;
  padding: 1.25rem 2rem;
  margin-top: 5px;
  margin-bottom: 16px;
}

.alert--error {
  background-color: #fff2f2;
  color: #6e3838;
}

.alert--success {
  background-color: #ceeedc;
  color: #285039;
}

.alert > span {
  margin-right: 1.6rem;
}


@keyframes slideFromTop {
  0% {
    transform: translate(-50%, -55%);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

/* MEDIA QUERIES */
@media (min-width: 375px) and (max-width: 575px) {
  .modal-dialogue {
    width: 100%;
    max-width: unset;
    height: 100vh;
    border-radius: 0;
  }
  .modal-dialogue input,
  .modal-dialogue textarea {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .modal-dialogue {
    width: inherit;
  }
}